import get from 'lodash/get';
import isNil from 'lodash/isNil';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import useLocaleStoreState from 'use-local-storage-state';

const FavouriteLocationContext = React.createContext({
  favouriteLocation: null,
  setFavouriteLocation: () => { return; },
});

export const useFavouriteLocation = () => useContext(FavouriteLocationContext);

const FavouriteLocationProvider = ({ children }) => {
  const [favouriteLocation, setFavouriteLocation] = useLocaleStoreState(
    'favouriteLocation',
    null,
  );

  const { uid } = useSelector(state => state.firebase.auth);

  const handleFavouriteLocation = (locationId) => {
    if(!isNil(favouriteLocation)) {
      favouriteLocation[uid] = get(favouriteLocation, uid, null) === locationId ? null : locationId;
      setFavouriteLocation(favouriteLocation);
    } else {
      const favLocation= {};
      favLocation[uid] = locationId;
      setFavouriteLocation(favLocation);
    }
  }

  const getFavouriteLocation = () => {
    return get(favouriteLocation, uid, null);
  };

  return (
    <FavouriteLocationContext.Provider
      value={{ setFavouriteLocation: handleFavouriteLocation, favouriteLocation: getFavouriteLocation() }}
    >
      {children}
    </FavouriteLocationContext.Provider>
  )
}

export default FavouriteLocationProvider;