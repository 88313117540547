import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { connect } from 'react-redux'
import { signOut } from 'redux/actions/Auth'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import IntlMessage from 'components/util-components/IntlMessage/IntlMessage.jsx'
import Spinner from 'components/dsi-components/Spinner/Spinner.jsx'
import FontelloIcon from 'components/util-components/FontelloIcon/FontelloIcon'

export class NavPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      orgName: '',
      isFetchingOrg: true,
      role: '',
    }
    this.showDrawer = this.showDrawer.bind()
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  setOrgName = () => {
    let orgId = this.props.organisationId
    if (orgId) {
      firebase
        .firestore()
        .collection('organisations')
        .doc(orgId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({
              ...this.state,
              orgName: doc.data().name,
              isFetchingOrg: false,
            })
          }
        })
        .catch(() => {
          this.setState({
            ...this.state,
            orgName: 'Org Not Assigned',
            isFetchingOrg: false,
          })
        })
    }
  }

  componentDidMount() {
    this.setOrgName();
    this.setState({
      ...this.state,
      role: this.props.role,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.organisationId !== this.props.organisationId) {
      this.setOrgName()
    }
    if (prevProps.role !== this.props.role) {
      this.setState({
        ...this.state,
        role: this.props.role,
      })
    }
  }


  render() {
    const { firstName, lastName } = this.props
    const { isFetchingOrg, orgName, role } = this.state
    const menuItems = [
      {
        key: 'my-account',
        label: (
          <Link to={`${APP_PREFIX_PATH}/profile`}>
            <IntlMessage id={'my_account'} />
          </Link>
        )
      },
      role === 'admin' && {
        key: 'newsroom',
        label: (
          <Link to={`${APP_PREFIX_PATH}/newsroom`}>
            <IntlMessage id={'newsroom'} />
          </Link>
        )
      },
      {
        key: 'logout',
        label: (<span className='text-primary'><IntlMessage id={'logout'} /></span>
        )
      }
    ]

    const menu = (
      <Menu items={menuItems} onClick={({ key }) => { if (key === 'logout') this.props.signOut() }} />
    )
    return (
      <>
        <Dropdown overlay={menu}>
          <Link
            className="ant-dropdown-link"
            to="/"
            onClick={(e) => e.preventDefault()}
          >
            {isFetchingOrg ? (
              <Spinner size={'medium'} />
            ) : (
              <>
                <span
                  style={{
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: 14 }}>{orgName}</span>
                  &nbsp;-&nbsp;
                  <span style={{ fontSize: 16 }}>{firstName} {lastName}</span>
                  <FontelloIcon
                    name={'icon-fleche-bas-listetswipe-n'}
                    style={{ fontSize: '15px', paddingLeft: 5, color: 'rgba(130,135,158,1)' }}
                  />
                </span>
              </>
            )}
          </Link>
        </Dropdown>
      </>
    )
  }
}

const mapStateToProps = ({ theme, firebase }) => {
  const { locale } = theme
  const { organisationId, firstName, lastName, role } = firebase.profile
  return { locale, organisationId, firstName, lastName, role }
}

export default connect(mapStateToProps, { signOut })(NavPanel)
