import { DashboardOutlined } from '@ant-design/icons'
import { APP_NAME, APP_PREFIX_PATH } from 'configs/AppConfig'

const administratorNavTree = [
  {
    key: 'administrator',
    title: 'sidenav.administrator',
    icon: DashboardOutlined,
    breadcrumb: false,
    access: ['admin','supervisor'],
    submenu: [
      {
        key: 'organisations',
        path: `${APP_PREFIX_PATH}/organisations`,
        title: 'sidenav.administrator.organisations',
        icon: 'icon-accueil-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor'],
      },
      {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        title: 'sidenav.administrator.users',
        icon: 'icon-a-propos-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor'],
      },
      {
        key: 'locations',
        path: `${APP_PREFIX_PATH}/locations`,
        title: 'sidenav.administrator.locations',
        icon: 'icon-localisation-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor'],
      },
      {
        key: 'sensors',
        path: `${APP_PREFIX_PATH}/devices`,
        title: 'sidenav.administrator.sensors',
        icon: 'icon-capteur-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor'],
      },
      // {
      //   key: 'contracts',
      //   path: `${APP_PREFIX_PATH}/contracts`,
      //   title: 'sidenav.administrator.contracts',
      //   icon: 'icon-cgu-n',
      //   breadcrumb: false,
      //   submenu: [],
      //   access: ['admin','supervisor'],
      // },
      {
        key: 'configuration_profiles',
        path: `${APP_PREFIX_PATH}/configuration-profiles`,
        title: 'sidenav.administrator.configuration-profiles',
        icon: 'icon-cgu-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin'],
      },
      {
        key: 'partners',
        path: `${APP_PREFIX_PATH}/partners`,
        title: 'sidenav.administrator.partners',
        icon: 'icon-confidentialité-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin'],
      },
      {
        key: 'new_client',
        path: `${APP_PREFIX_PATH}/newclient`,
        title: 'sidenav.administrator.new.client',
        icon: 'icon-plus-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor'],
      },
    ],
  },
]

const userNavTree = [
  {
    key: 'users',
    title: (`sidenav.general.my_${APP_NAME}`).toLowerCase(),
    icon: DashboardOutlined,
    breadcrumb: false,
    access: ['admin','supervisor', 'user', 'manager'],
    submenu: [
      {
        key: 'places',
        path: `${APP_PREFIX_PATH}/locations/view`,
        title: 'sidenav.general.place',
        icon: 'icon-localisation-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor', 'user', 'manager'],
      },
      {
        key: 'devices',
        path: `${APP_PREFIX_PATH}/mydevices`,
        title: 'sidenav.general.sensors',
        icon: 'icon-capteur-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor', 'user', 'manager'],
      },
      {
        key: 'history',
        path: `${APP_PREFIX_PATH}/history/data`,
        title: 'sidenav.general.history',
        icon: 'icon-historique-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor', 'user', 'manager'],
      },
      {
        key: 'energy',
        path: `${APP_PREFIX_PATH}/energy`,
        title: 'sidenav.general.energy',
        icon: 'icon-energie-n',
        breadcrumb: false,
        submenu: [],
        access: ['admin','supervisor', 'user', 'manager'],
      },
      {
        key: 'alert_management',
        path: '',
        title: 'sidenav.general.alerts.management',
        icon: 'icon-alerte-n',
        access: ['admin','supervisor', 'user', 'manager'],
        breadcrumb: false,
        submenu: [
          {
            key: 'alerts',
            path: `${APP_PREFIX_PATH}/alerts`,
            title: 'sidenav.general.alerts',
            // icon: BellFilled,
            access: ['admin','supervisor', 'user', 'manager'],
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'alerts_history',
            path: `${APP_PREFIX_PATH}/alerts/history`,
            title: 'sidenav.general.alerts.history',
            // icon: HistoryOutlined,
            access: ['admin','supervisor', 'user', 'manager'],
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
]

const navigationConfig = [...userNavTree, ...administratorNavTree]

export default navigationConfig
