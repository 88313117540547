import React from 'react'
import { Spin } from 'antd'

export default function Spinner({size}) {
  return (
    <div className='centerJustify center fullw' style={{ height: 'inherit' }}>
      <Spin size={size} />
    </div>
  )
}
