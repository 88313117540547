import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { useSelector } from 'react-redux'
import navigationConfig from 'configs/NavigationConfig'
import IntlMessage from 'components/util-components/IntlMessage/IntlMessage.jsx'

let breadcrumbData = {
  '/app': <IntlMessage id="home" />,
}

const BreadcrumbRoute = withRouter((props) => {
  const { location } = props
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const buildBreadcrumb = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    const { role } = useSelector(({ firebase }) => {
      return firebase.profile
    })
    navigationConfig
      .filter((menuAccess) => menuAccess.access.includes(role))
      .forEach((elm) => {
        const assignBreadcrumb = (obj) =>
          (breadcrumbData[obj.path] = obj.title && <IntlMessage id={obj?.title} />)
        assignBreadcrumb(elm)
        if (elm.submenu) {
          elm.submenu
            .filter((subMenu) => subMenu.access.includes(role))
            .forEach((elm) => {
              assignBreadcrumb(elm)
              if (elm.submenu) {
                elm.submenu.forEach((elm) => {
                  assignBreadcrumb(elm)
                })
              }
            })
        }
      })
    return (
      <Breadcrumb.Item key={url + index}>
        <Link to={url}>{breadcrumbData[url]}</Link>
      </Breadcrumb.Item>
    )
  })

  return <Breadcrumb>{buildBreadcrumb}</Breadcrumb>
})

export class AppBreadcrumb extends Component {
  render() {
    return <BreadcrumbRoute />
  }
}

export default AppBreadcrumb
