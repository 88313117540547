import React from 'react'
import IntlMessage from 'components/util-components/IntlMessage/IntlMessage.jsx'
const { version } = require('./../../../package.json')

export default function Footer() {
  return (
    <footer className="footer">
      <span style={{color:'rgba(135,145,167,1)'}}>Copyright Demand Side - v{version} - <span style={{color:'rgba(101,107,136,1)'}}>{`${new Date().getFullYear()}`}</span></span>
      <span>
        <a
          href="https://iotearthia.fr/pdc-earthia/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <IntlMessage id={'privacy_policy'} />
        </a>
        {' | '}
        <a
          href="https://iotearthia.fr/cgu-earthia/"
          target="_blank"
          rel="noreferrer"
        >
          <IntlMessage id={'terms_of_service'} />
        </a>
      </span>
    </footer>
  )
}
