import React from 'react'
import PropTypes from 'prop-types'

const FontelloIcon = ({ name, className, style, onClick }) => {
  return <i className={name + ' ' + className} style={{...style,cursor: onClick && 'pointer'}} onClick={onClick} />
}

FontelloIcon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

export default FontelloIcon
