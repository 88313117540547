import React from 'react'
import { Grid } from 'antd'
import { connect } from 'react-redux'
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from 'constants/ThemeConstant'
import { APP_NAME } from 'configs/AppConfig'
import utils from 'utils'
import { Link } from 'react-router-dom'

const { useBreakpoint } = Grid

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (props) => {
  const { navCollapsed, logoType } = props
  if (logoType === 'light') {
    if (navCollapsed) {
      return '/img/logo-sm-white.png'
    }
    return '/img/logo-white.png'
  }

  if (navCollapsed) {
    return '/img/logo-sm.png'
  }
  return '/img/logo.png'
}

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const { navCollapsed } = props
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      <Link to='/'>
        <img
          src={getLogo(props)}
          alt={`${APP_NAME} logo`}
          width={navCollapsed ? '50px' : '120px'}
        />
      </Link>
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme
  return { navCollapsed, navType }
}

export default connect(mapStateToProps)(Logo)
