/* eslint-disable no-unused-vars */
import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Grid } from 'antd'
import { connect, useSelector } from 'react-redux'
import { onMobileNavToggle } from 'redux/actions/Theme'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import navigationConfig from 'configs/NavigationConfig'
import utils from 'utils'
import IntlMessage from '../util-components/IntlMessage/IntlMessage'
import FontelloIcon from 'components/util-components/FontelloIcon/FontelloIcon'

const { useBreakpoint } = Grid

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString()

const setDefaultOpen = (key) => {
  let keyList = []
  let keyString = ''
  if (key) {
    const arr = key.split('-')
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const getMenuItem = (menu, localization) => {
  return {
    key: menu.key,
    icon: menu.icon ? <FontelloIcon name={menu?.icon} style={{ fontSize: 19 }} /> : null,
    label: (<><span>{setLocale(localization, menu?.title)}</span>{menu.path ? <Link to={menu.path} /> : null}</>)
  }
}

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    navCollapsed,
    onMobileNavToggle,
  } = props
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const { role, isUserWithAlert = true } = useSelector(({ firebase }) => {
    return firebase.profile
  })
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }

  const getSubMenuChildren = (subMenuFirst) => {
    return subMenuFirst.submenu.map((subMenuSecond) => {
      return {
        key: subMenuSecond.key,
        icon: subMenuSecond.icon ? (<FontelloIcon name={subMenuSecond?.icon} style={{ fontSize: 19 }} />) : null,
        label: (<>
          <span className='ml-3' style={{ fontSize: 14 }}>
            {setLocale(localization, subMenuSecond.title)}
          </span>
          <Link
            onClick={() => closeMobileNav()}
            to={subMenuSecond.path}
          />
        </>)
      }
    })
  }

  const getSubMenuItem = (subMenuFirst) => {
    return {
      key: subMenuFirst.key,
      icon: subMenuFirst.icon ? (<FontelloIcon name={subMenuFirst.icon} style={{ fontSize: 19, color: subMenuFirst.key === 'new_client' ? 'rgba(235,89,101,1)' : '' }} />) : null,
      label: (
        <Link
          onClick={() => closeMobileNav()}
          to={subMenuFirst.path}
        >
          <span style={{
            fontSize: 14,
            color: subMenuFirst.key === 'new_client' ? 'rgba(235,89,101,1)' : undefined
          }}>{setLocale(localization, subMenuFirst.title)}</span>
        </Link>
      )
    }
  }

  const getMenuGroupChildren = (menu) => {
    return menu.submenu
      .filter((subMenu) => subMenu.access.includes(role))
      .filter((subMenu) => subMenu.key == 'alert_management' ? isUserWithAlert : true)
      .map((subMenuFirst) => {
        if (subMenuFirst.submenu.length > 0) {
          return {
            key: subMenuFirst.key,
            label: (!navCollapsed && <span style={{ fontSize: 14 }}>{setLocale(localization, subMenuFirst.title)}</span>),
            children: getSubMenuChildren(subMenuFirst),
            icon: subMenuFirst.icon ? <FontelloIcon name={subMenuFirst?.icon} style={{ fontSize: 19 }} /> : null
          }
        } else {
          return getSubMenuItem(subMenuFirst)
        }
      })
  }

  const menuItems = navigationConfig.filter((menuAccess) => menuAccess.access.includes(role)).map((menu) => {
    if (menu.submenu.length > 0) {
      return {
        type: 'group',
        label: menu?.title && setLocale(localization, menu.title),
        children: getMenuGroupChildren(menu)
      }
    } else {
      return getMenuItem(menu, localization)
    }
  });

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
      items={menuItems}
    />
  )
}

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;

  const getSubMenuChildren = (subMenuFirst) => {
    return subMenuFirst.submenu.map((subMenuSecond) => {
      return {
        key: subMenuSecond.key,
        label: (<>
          <span style={[(subMenuSecond.key === 'alerts' || subMenuSecond.key === 'alerts_history') ? { color: 'red' } : {}]}>
            {setLocale(localization, subMenuSecond.title)}
          </span>
          <Link to={subMenuSecond.path} />
        </>)
      }
    });
  }

  const getSubMenuItem = (menu) => {
    return menu.submenu.map((subMenuFirst) => {
      if (subMenuFirst.submenu.length > 0) {
        return {
          key: subMenuFirst.key,
          icon: subMenuFirst.icon ? (<FontelloIcon name={subMenuFirst?.icon} style={{ fontSize: 19 }} />) : null,
          label: setLocale(localization, subMenuFirst.title),
          children: getSubMenuChildren(subMenuFirst)
        }
      } else {
        return {
          key: subMenuFirst.key,
          icon: subMenuFirst.icon ? (<FontelloIcon name={subMenuFirst?.icon} style={{ fontSize: 19 }} />) : null,
          label: <><span>{setLocale(localization, subMenuFirst.title)}</span><Link to={subMenuFirst.path} /></>
        }
      }
    })
  }

  const menuItems = navigationConfig.map((menu) => {
    if (menu.submenu.length > 0) {
      return {
        key: menu.key,
        popupClassName: "top-nav-menu",
        icon: menu.icon ? <FontelloIcon name={menu?.icon} style={{ fontSize: 19 }} /> : null,
        label: menu?.title && <span>{setLocale(localization, menu.title)}</span>,
        children: getSubMenuItem(menu)
      }
    } else {
      return getMenuItem(menu, localization)
    }
  })

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }} items={menuItems} />
  )
}

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  )
}

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor, navCollapsed } = theme
  return { sideNavTheme, topNavColor, navCollapsed }
}

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent)
