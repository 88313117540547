import get from 'lodash/get'
import isString from 'lodash/isString'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export const AVAILABLE_COMMAND_TYPES = [
  { key: 'manual', i18nKey: 'devices.manualControl' },
  { key: 'manual-with-timer', i18nKey: 'devices.manualControlWithTimer' },
  { key: 'schedule', i18nKey: 'devices.scheduleControl' },
  { key: 'remove-schedule', i18nKey: 'devices.removeScheduleControl' },
  { key: 'periodic-schedule', i18nKey: 'devices.periodicScheduleControl' }
]

export const getDisplay = (type) => {
  switch (type) {
    case 'temperature':
      return {
        title: 'Température',
        iconName: 'thermometer',
        iconType: 'weather',
        unit: ' °C',
      }
    case 'probe_temperature':
      return {
        title: 'Sonde de température',
        iconName: 'dock',
        iconType: 'zmdi',
        unit: ' °C',
      }
    case 'target_temperature':
      return {
        title: 'Température de consigne',
        iconName: 'dock',
        iconType: 'zmdi',
        unit: ' °C',
      }
    case 'humidity':
      return {
        title: 'Humidité',
        iconName: 'humidity',
        iconType: 'weather',
        unit: ' %',
      }
    case 'pressure':
      return {
        title: 'Pression',
        iconName: 'barometer',
        iconType: 'weather',
        unit: ' hPa',
      }
    case 'co2':
      return {
        title: 'Taux de CO2',
        iconName: 'grain',
        iconType: 'zmdi',
        unit: ' ppm',
      }
    case 'tvoc':
      return {
        title: 'Taux de COV',
        iconName: 'blur',
        iconType: 'zmdi',
        unit: ' ppb',
      }
    case 'brightness':
      return {
        title: 'Luminosité',
        iconName: 'lamp',
        iconType: 'zmdi',
        unit: ' lux',
      }
    case 'movement':
      return {
        title: 'Nombre de passages',
        iconName: 'run',
        iconType: 'zmdi',
        unit: '',
      }
    case 'acceleration_x':
      return {
        title: 'Accélération X',
        iconName: 'trending-up',
        iconType: 'zmdi',
        unit: ' g',
      }
    case 'acceleration_y':
      return {
        title: 'Accélération Y',
        iconName: 'trending-up',
        iconType: 'zmdi',
        unit: ' g',
      }
    case 'acceleration_z':
      return {
        title: 'Accélération Z',
        iconName: 'trending-up',
        iconType: 'zmdi',
        unit: ' g',
      }
    case 'voltage':
      return {
        title: 'Tension',
        iconName: 'flash',
        iconType: 'zmdi',
        unit: ' V',
      }
    case 'current':
      return {
        title: 'Intensité',
        iconName: 'format-italic',
        iconType: 'zmdi',
        unit: ' A',
      }
    case 'average_current_phase1':
      return {
        title: 'Intensité moyenne phase 1',
        iconName: 'format-italic',
        iconType: 'zmdi',
        unit: ' A',
      }
    case 'average_current_phase2':
      return {
        title: 'Intensité moyenne phase 2',
        iconName: 'format-italic',
        iconType: 'zmdi',
        unit: ' A',
      }
    case 'average_current_phase3':
      return {
        title: 'Intensité moyenne phase 3',
        iconName: 'format-italic',
        iconType: 'zmdi',
        unit: ' A',
      }
    case 'max_current_phase1':
      return {
        title: 'Intensité max. phase 1',
        iconName: 'format-italic',
        iconType: 'zmdi',
        unit: ' A',
      }
    case 'max_current_phase2':
      return {
        title: 'Intensité max. phase 2',
        iconName: 'format-italic',
        iconType: 'zmdi',
        unit: ' A',
      }
    case 'max_current_phase3':
      return {
        title: 'Intensité max. phase 3',
        iconName: 'format-italic',
        iconType: 'zmdi',
        unit: ' A',
      }
    case 'instant_power':
      return {
        title: 'Puissance instantanée',
        iconName: 'input-power',
        iconType: 'zmdi',
        unit: ' W',
        plotType: 'column'
      }
    case 'power':
      return {
        title: 'Puissance',
        iconName: 'input-power',
        iconType: 'zmdi',
        unit: ' W',
      }
    case 'power_max_24':
      return {
        title: 'Puissance max. journalière',
        iconName: 'input-power',
        iconType: 'zmdi',
        unit: ' W',
        aggregator: 'max',
      }
    case 'apparent_power':
      return {
        title: 'Puissance apparente',
        iconName: 'input-power',
        iconType: 'zmdi',
        unit: ' VA',
        plotType: 'column'
      }
    case 'apparent_power_max_24':
      return {
        title: 'Puissance max. journalière',
        iconName: 'input-power',
        iconType: 'zmdi',
        unit: ' VA',
        aggregator: 'max',
      }
    case 'energy':
      return {
        title: 'Consommation d\'énergie',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'sum',
        plotType: 'column'
      }
    case 'energy_1':
      return {
        title: 'Consommation horaire',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'sum',
        plotType: 'column'
      }
    case 'energy_24':
      return {
        title: 'Consommation journalière',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'max',
        plotType: 'column'
      }
    case 'energy_peak_24':
      return {
        title: 'Consommation journalière HP',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'max',
        plotType: 'column'
      }
    case 'energy_off_peak_24':
      return {
        title: 'Consommation journalière HC',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'max',
        plotType: 'column'
      }
    case 'cumulative_energy':
      return {
        title: 'Consommation d\'énergie cumulée',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'max'
      }
    case 'cumulative_energy_peak':
      return {
        title: 'Consommation d\'énergie HP cumulée',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'max'
      }
    case 'cumulative_energy_off_peak':
      return {
        title: 'Consommation d\'énergie HC cumulée',
        iconName: 'input-composite',
        iconType: 'zmdi',
        unit: ' Wh',
        aggregator: 'max'
      }
    case 'energy_price':
      return {
        title: 'Tendance (€)',
        iconName: 'card',
        iconType: 'zmdi',
        unit: ' €',
        aggregator: 'max',
        plotType: 'column'
      }
    case 'energy_price_1':
      return {
        title: 'Tendance horaire (€)',
        iconName: 'card',
        iconType: 'zmdi',
        unit: ' €',
        aggregator: 'max',
        plotType: 'column'
      }
    case 'energy_price_24':
      return {
        title: 'Tendance journalière (€)',
        iconName: 'card',
        iconType: 'zmdi',
        unit: ' €',
        aggregator: 'max',
        plotType: 'column'
      }
    case 'carbon_impact':
      return {
        title: 'Impact carbone',
        iconName: 'globe',
        iconType: 'zmdi',
        unit: ' g'
      }
    case 'carbon_trend':
      return {
        title: 'Tendance carbone',
        iconName: 'globe',
        iconType: 'zmdi',
        unit: ' g'
      }
    case 'electricity_weather':
      return {
        title: 'Météo de l\'électricité',
        iconName: 'earthquake',
        iconType: 'weather',
        unit: ' ',
        aggregator: 'max',
        plotType: 'area',
        mapping: ['Normal', 'Tendue', 'Très tendue'],
      }
    case 'switch_status':
      return {
        title: 'Interrupteur',
        iconName: 'power',
        iconType: 'zmdi',
        unit: '',
        aggregator: "none",
        plotType: "area",
        // O: off, 1: on
        mapping: ['Off', 'On'],
      }
    case 'button_state':
      return {
        title: 'Etat du bouton',
        iconName: 'flare',
        iconType: 'zmdi',
        unit: '',
        aggregator: "none",
        plotType: "area"
      }
    case 'led_state':
      return {
        title: 'Voyant lumineux',
        iconName: 'lamp',
        iconType: 'zmdi',
        unit: '',
        aggregator: "none",
        plotType: "area",
        // O: off, 1: on
        mapping: ['Off', 'On'],
      }
    case 'schedule_mode':
      return {
        title: 'Mode du planning',
        iconName: 'collection-text',
        iconType: 'zmdi',
        unit: '',
        aggregator: "none",
        plotType: "area",
        // O: off, 1: comfort, 2: frostfree, 3: economic, 4: comfort-1, 5: comfort-2
        mapping: ['Off', 'Confort', 'Hors-gel', 'Eco', 'Confort -1', 'Confort -2'],
      }
    default:
      return {
        title: type,
        iconName: 'help-outline',
        iconType: 'zmdi',
        unit: ''
      }
  }
}

export const getAvailableTypes = () => {
  return [
    { id: 'temperature', title: 'type_temperature' },
    { id: 'probe_temperature', title: 'type_probe_temperature' },
    { id: 'target_temperature', title: 'type_target_temperature' },
    { id: 'humidity', title: 'type_humidity' },
    { id: 'pressure', title: 'type_pressure' },
    { id: 'co2', title: 'type_co2' },
    { id: 'tvoc', title: 'type_tvoc' },
    { id: 'brightness', title: 'type_brightness' },
    { id: 'movement', title: 'type_movement' },
    { id: 'acceleration_x', title: 'acceleration_x' },
    { id: 'acceleration_y', title: 'acceleration_y' },
    { id: 'acceleration_z', title: 'acceleration_z' },
    { id: 'voltage', title: 'type_voltage' },
    { id: 'current', title: 'type_current' },
    { id: 'average_current_phase1', title: 'type_average_current_phase1' },
    { id: 'average_current_phase2', title: 'type_average_current_phase2' },
    { id: 'average_current_phase3', title: 'type_average_current_phase3' },
    { id: 'max_current_phase1', title: 'type_max_current_phase1' },
    { id: 'max_current_phase2', title: 'type_max_current_phase2' },
    { id: 'max_current_phase3', title: 'type_max_current_phase3' },
    { id: 'instant_power', title: 'type_instant_power' },
    { id: 'power', title: 'type_power' },
    { id: 'power_max_24', title: 'daily_max_power' },
    { id: 'apparent_power', title: 'type_apparent_power' },
    { id: 'apparent_power_max_24', title: 'type_apparent_daily_power_max' },
    { id: 'energy', title: 'type_energy' },
    { id: 'energy_1', title: 'hourly_consumption' },
    { id: 'energy_24', title: 'daily_consumption' },
    { id: 'energy_peak_24', title: 'peak_daily_consumption' },
    { id: 'energy_off_peak_24', title: 'off_peak_daily_consumption' },
    { id: 'cumulative_energy', title: 'type_cumulative_energy' },
    { id: 'cumulative_energy_peak', title: 'type_cumulative_energy_peak' },
    { id: 'cumulative_energy_off_peak', title: 'type_cumulative_energy_off_peak' },
    { id: 'energy_price', title: 'type_energy_price' },
    { id: 'energy_price_1', title: 'type_energy_price_1' },
    { id: 'energy_price_24', title: 'type_energy_price_24' },
    { id: 'carbon_impact', title: 'type_carbon_impact' },
    { id: 'carbon_trend', title: 'type_carbon_trend' },
    { id: 'electricity_weather', title: 'type_electricity_weather' },
    { id: 'switch_status', title: 'type_switch_status' },
    { id: 'button_state', title: 'type_button_state' },
    { id: 'led_state', title: 'type_led_state' },
    { id: 'schedule_mode', title: 'type_schedule_mode' },
  ]
}

export const roundToFixed = (value, precision) => {
  const factor = Math.max(Math.pow(10, precision), 1);
  return Math.round(value * factor) / factor;
}

export const getDisplayValue = (value, metric) => {
  const defautValue = 'N/A'
  const display = getDisplay(metric);

  let type = typeof value;
  // Check if metric has a mapping
  if (!isEmpty(display.mapping)) {
    type = 'mapping';
  }
  const mappingKeys = display.mapping || [];
  const threshold = 1000;
  const precision = 2;
  let absValue = value;

  // Default values
  let v = value;
  let u = display.unit || ' ';

  switch (type) {
    case 'object': // null value as type object 
      v = defautValue;
      break;
    case 'mapping':
      v = mappingKeys[value] || defautValue;
      break;
    case 'number':
      absValue = Math.abs(value);
      if (absValue >= threshold * threshold) {
        v = roundToFixed(value / (threshold * threshold), precision);
        u = ` M${u.trim()}`
      } else if (absValue >= 10 * threshold) {
        v = roundToFixed(value / threshold, precision);
        u = ` k${u.trim()}`
      } else {
        v = roundToFixed(value, precision);
      }
      break;
    default:
      // Keep default values
      break;
  }
  return { value: v, unit: u };
}

export const getDefaultMetricType = (resource) => {
  const availableTypes = getAvailableTypes();
  const selector = resource.type || resource.name;
  const metricType = find(availableTypes, {
    id: selector
  }) || {};
  return metricType.id || '';
}

export const getReferenceWithoutVersion = (deviceReference) => {
  return isString(deviceReference) ? (deviceReference).split('_').slice(0, -1).join('_') : '';
}

export const getDefaultControllerType = (data) => {
  const deviceReference = getReferenceWithoutVersion(data.reference);
  switch (deviceReference) {
    case 'mic_lorawan_mlr003':
    case 'mcl_lorawan_vicki':
      return 'heater'
    case 'dsi_lorawan_edge':
      return 'ac'
    case 'dsi_lorawan_led-controller':
      return 'ledController';
    case 'dsi_lorawan_wire-pilot':
      return 'electricHeater';
    case 'dsi_api_linky_v1.0.0':
    case 'dsi_api_linky':
    case 'ene_api_linky':
    case 'ene_sge_linky':
    case 'ene_sge_linky-c5':
      return 'electricMeter'
    default:
      break;
  }
  return '';
}

export const getCommand = (type, controllerType) => {
  const defaultCommandData = { template: "", off: "", daily: "", night: "", intense: "", comfort: "", economic: "", default: "" };
  const commandsDataMapping = {
    'ac': {
      'manual': {
        template: '{"tiny_ir_cmd_id": "#cmdValue#"}',
        off: "ACTION_OFF",
        daily: "ACTION_1",
        night: "ACTION_2",
        comfort: "ACTION_3",
        intense: "ACTION_4",
        default: "ACTION_OFF"
      },
      'manual-with-timer': {
        template: '{"tiny_ir_cmd_id": "#cmdValue#", "tiny_ir_cmd_during_x_minutes": #cmdDuration#}',
        off: "ACTION_OFF",
        daily: "ACTION_1",
        night: "ACTION_2",
        comfort: "ACTION_3",
        intense: "ACTION_4",
        default: "ACTION_OFF"
      },
      'schedule': {
        template: '{"scheduler_type": "action", "periods": #periods#}',
        off: 0,
        daily: 1,
        night: 2,
        comfort: 3,
        intense: 4,
        default: 0
      },
      'remove-schedule': {
        template: '{ "scheduler_type": "action", "monday": true, "tuesday": true, "wednesday": true, "thursday": true, "friday": true, "saturday": true, "sunday": true }'
      }
    },
    'heater': {
      'manual': {
        template: '{"target_temperature": #cmdValue#}',
        off: 0,
        daily: 21,
        night: 25,
        economic: 18,
        comfort: 23,
        default: 17
      }
    },
    'electricHeater': {
      'schedule': {
        template: '{"scheduler_type": "action", "periods": #periods#}',
        off: 0,
        comfort: 1,
        'comfort-1': 2,
        'comfort-2': 3,
        frostfree: null,
        economic: 4,
        default: 0
      },
      'remove-schedule': {
        template: '{ "scheduler_type": "action", "monday": true, "tuesday": true, "wednesday": true, "thursday": true, "friday": true, "saturday": true, "sunday": true }'
      },
      'manual': {
        template: '{ "wire_pilot_mode": "#cmdValue#" }',
        off: 'OFF',
        comfort: 'COMFORT',
        frostfree: 'NO FROST',
        economic: 'ECO',
        'comfort-1': 'COMFORT -1',
        'comfort-2': 'COMFORT -2',
        default: 'OFF'
      },
      'manual-with-timer': {
        template: '{ "wire_pilot_mode": "#cmdValue#", "time": #cmdDuration# }',
        off: 'OFF',
        comfort: 'COMFORT',
        frostfree: 'NO FROST',
        economic: 'ECO',
        'comfort-1': 'COMFORT -1',
        'comfort-2': 'COMFORT -2',
        default: 'OFF'
      }
    },
    'ledController': {
      'schedule': {
        template: '{"scheduler_type": "action", "periods": #periods#}',
        off: 0,
        on: 1,
        default: 0
      },
      'remove-schedule': {
        template: '{ "scheduler_type": "action", "monday": true, "tuesday": true, "wednesday": true, "thursday": true, "friday": true, "saturday": true, "sunday": true }'
      },
      'manual': {
        template: '{"led_controller_cmd_id": "#cmdValue#"}',
        off: "ACTION_OFF",
        on: "ACTION_ON",
        default: "ACTION_OFF"
      },
      'manual-with-timer': {
        template: '{"blinking_cmd_during_x_minutes": #cmdDuration#}',
        off: "ACTION_OFF",
        on: "ACTION_ON",
        default: "ACTION_OFF"
      },
    }
  }

  return get(commandsDataMapping, `${controllerType}.${type}`, defaultCommandData);
}

export const AC_MODES = ['Off', 'Daily', 'Night', 'Comfort', 'Intense', 'Default']
export const HEATER_MODES = ['Off', 'Daily', 'Night', 'Comfort', 'Economic', 'Default']
export const ELECTRIC_HEATER_MODES = ['Off', 'Comfort', 'Frostfree', 'Economic', 'Comfort-1', 'Comfort-2', 'Default'];
export const LED_MODES = ['Off', 'On', 'Default'];
