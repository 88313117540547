import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import Loading from "components/shared-components/Loading/Loading";
import { isEmpty, isLoaded } from "react-redux-firebase";
import Spinner from "components/dsi-components/Spinner/Spinner";
import { signOut } from "redux/actions/Auth";
import { has } from "lodash";

export const AppViews = () => {
  const dispatch = useDispatch();

  const profile = useSelector(({ firebase }) => {
    return firebase.profile;
  });

  if (!isLoaded(profile)) {
    return <Spinner size={"large"} />;
  }

  if (isEmpty(profile)) {
    dispatch(signOut());
    return null;
  }

  const { role, applications } = profile;
  const showAdminPage = role === "admin";
  const showSupervisorPage = role === "supervisor";
  const showManagerPage = role === "manager";
  const showUserWithAlertsPage = has(applications, 'topology') && applications.alerts && role === 'user';

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./ProfilePage/Profile`))}
        />
        {
          showAdminPage &&
          <Route
            path={`${APP_PREFIX_PATH}/newsroom`}
            component={lazy(() => import(`./NewsroomPage/NewsroomPage`))}
          />
        }
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/locations/create`}
            component={lazy(() => import(`./CreateLocationPage/Locations`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/locations/view`}
          component={lazy(() => import(`./LocationViewPage/LocationView`))}
        />

        {(showAdminPage || showSupervisorPage || showManagerPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/locations/zone/create`}
            component={lazy(() => import(`./CreateZonePage/Locations`))}
          />
        )}
        {(showAdminPage || showSupervisorPage || showManagerPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/locations/zone/:id/edit`}
            component={lazy(() => import(`./CreateZonePage/Locations`))}
          />
        )}
        {(showAdminPage || showSupervisorPage || showManagerPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/locations/:id/edit`}
            component={lazy(() => import(`./CreateLocationPage/Locations`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/locations/:id`}
          component={lazy(() => import(`./DeviceLocationPage/DeviceLocation`))}
        />
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/locations`}
            component={lazy(() => import(`./locationPage/Locations`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/organisations/create`}
            component={lazy(() => import(`./CreateOrganizationPage/CreateOrganizationPage`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/organisations/:id/edit`}
            component={lazy(() => import(`./CreateOrganizationPage/CreateOrganizationPage`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/organisations`}
            component={lazy(() => import(`./organisationPage/Organisations`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/users/:id/edit`}
            component={lazy(() => import(`./CreateUserPage/CreateUser`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/users/create`}
            component={lazy(() => import(`./CreateUserPage/CreateUser`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/users`}
            component={lazy(() => import(`./userPage/Users`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/devices/:id/history`}
          component={lazy(() => import(`./DeviceHistoryPage/DeviceHistory`))}
        />
        {showAdminPage && (
          <Route
            path={`${APP_PREFIX_PATH}/devices/:id/edit`}
            component={lazy(() => import(`./CreateDevicePage/Device`))}
          />
        )}

        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/devices/create`}
            component={lazy(() => import(`./CreateDevicePage/Device`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/devices/replace`}
            component={lazy(() => import(`./ReplaceDevicePage/ReplaceDevicePage`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/devices/:id/exports/history`}
          component={lazy(() => import("./ExportDevicePage/ExportDeviceHistory"))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/devices/:id`}
          component={lazy(() => import(`./DeviceDetailsPage/SensorDetail`))}
        />
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/devices`}
            component={lazy(() => import(`./DevicesPage/Sensors`))}
          />
        )}
        <Route
          path={`${APP_PREFIX_PATH}/mydevices`}
          component={lazy(() => import(`./MyDevicesPage/MySensors`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/history/data`}
          component={lazy(() => import(`./DataHistoryPage/DataHistoryPage`))}
        />
        {(showAdminPage || showSupervisorPage || showManagerPage || showUserWithAlertsPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/alerts/:id/edit`}
            component={lazy(() => import(`./Alerts/CreateAlertPage/CreateAlertPage`))}
          />
        )}
        {(showAdminPage || showSupervisorPage || showManagerPage || showUserWithAlertsPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/alerts/history`}
            component={lazy(() => import(`./Alerts/HistoryAlertsPage/HistoryAlertsPage`))}
          />
        )}
        {(showAdminPage || showSupervisorPage || showManagerPage || showUserWithAlertsPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/alerts/add`}
            component={lazy(() => import(`./Alerts/CreateAlertPage/CreateAlertPage`))}
          />
        )}
        {(showAdminPage || showSupervisorPage || showManagerPage || showUserWithAlertsPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/alerts`}
            component={lazy(() => import(`./Alerts/AlertsPage/AlertsPage`))}
          />
        )}

        {(showAdminPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/configuration-profiles`}
            component={lazy(() => import(`./ConfigurationProfilesPage/ConfigurationProfiles`))}
          />
        )}

        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/contracts/create`}
            component={lazy(() => import(`./CreateContractPage/CreateContract`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/contracts/:id/edit`}
            component={lazy(() => import(`./CreateContractPage/CreateContract`))}
          />
        )}
        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/contracts`}
            component={lazy(() => import(`./ContractsPage/Contracts`))}
          />
        )}

        {showAdminPage && (
          <Route
            path={`${APP_PREFIX_PATH}/partners/create`}
            component={lazy(() => import(`./CreatePartnerPage/CreatePartner`))}
          />
        )}
        {showAdminPage && (
          <Route
            path={`${APP_PREFIX_PATH}/partners/:id/edit`}
            component={lazy(() => import(`./CreatePartnerPage/CreatePartner`))}
          />
        )}
        {showAdminPage && (
          <Route
            path={`${APP_PREFIX_PATH}/partners`}
            component={lazy(() => import(`./PartnersPage/Partners`))}
          />
        )}

        {(showAdminPage || showSupervisorPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/newclient`}
            component={lazy(() => import(`./NewClientPage/NewClient`))}
          />
        )}
        {(showAdminPage || showSupervisorPage || showManagerPage || showUserWithAlertsPage) && (
          <Route
            path={`${APP_PREFIX_PATH}/energy`}
            component={lazy(() => import(`./EnergyPage/EnergyPage`))}
          />
        )}
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/locations/view`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
