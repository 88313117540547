import React from 'react'
import { Menu, Dropdown } from 'antd'
import { GlobalOutlined, DownOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { onLocaleChange } from 'redux/actions/Theme'
import lang from 'assets/data/language.data.json'
import FontelloIcon from 'components/util-components/FontelloIcon/FontelloIcon'
import { useIntl } from 'react-intl';
import lowerFirst from 'lodash/lowerFirst';

function getLanguageDetail(locale) {
  const data = lang.filter((elm) => elm.langId === locale)
  return data[0]
}

const SelectedLanguage = ({ locale }) => {
  const { formatMessage } = useIntl();
  const language = getLanguageDetail(locale)
  const { langName, icon } = language
  return (
    <div className="d-flex align-items-center">
      <img
        style={{ maxWidth: '20px' }}
        src={`/img/flags/${icon}.png`}
        alt={langName}
      />
      <span className="font-weight-semibold ml-2">
        {formatMessage({ id: lowerFirst(langName) })} <DownOutlined className="font-size-xs" />
      </span>
    </div>
  )
}

export const NavLanguage = ({ locale, configDisplay, onLocaleChange }) => {
  const { formatMessage } = useIntl();
  const languageOptionMenuItems = lang.map(elm => {
    return {
      key: elm.langName,
      label: (
        <span onClick={() => onLocaleChange(elm.langId)} className={locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}>
          <span className="d-flex justify-content-between align-items-center">
            <div>
              <img
                style={{ maxWidth: '20px' }}
                src={`/img/flags/${elm.icon}.png`}
                alt={elm.langName}
              />
              <span className="font-weight-normal ml-2">{formatMessage({ id: lowerFirst(elm.langName) })}</span>
            </div>
            {locale === elm.langId ? (
              <FontelloIcon
                name={'icon-valider-n'}
                style={{ fontSize: '16px', color: '#48ae4d' }}
              />
            ) : null}
          </span>
        </span>
      )
    }
  });

  const dropdownMenuItems = [{ key: 'language', label: <a href="#/" onClick={(e) => e.preventDefault()}><GlobalOutlined className="nav-icon mr-0" /></a> }]

  const languageOption = (
    <Menu items={languageOptionMenuItems} />
  )
  return (
    <Dropdown
      placement="bottomRight"
      overlay={languageOption}
      trigger={['click']}
    >
      {configDisplay ? (
        <a href="#/" className="text-gray" onClick={(e) => e.preventDefault()}>
          <SelectedLanguage locale={locale} />
        </a>
      ) : (
        <Menu mode="horizontal" items={dropdownMenuItems} />
      )}
    </Dropdown>
  )
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme
  return { locale }
}

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage)
